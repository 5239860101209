
import { Component, Prop } from 'vue-property-decorator';
import { Venue } from '@/interfaces/models/Venue';
import Notification from '@/mixins/Notification';
import { mixins } from 'vue-class-component';
import { WoltApiService } from '@/api/http/WoltApiService';
import { UberEatsApiService } from '@/api/http/UberEatsApiService';
import { JetApiService } from '@/api/http/JetApiService';

@Component
export default class SyncExternalFoodcardsButton extends mixins(Notification) {
  @Prop({ type: Object, required: true }) public venue!: Venue;

  get canSync(): boolean {
    return this.isEnabled && (this.isWoltEnabled || this.isUberEatsEnabled || this.isJetEnabled);
  }

  get isEnabled(): boolean {
    return !!this.venue?.externalOrderProviders?.useExternalProviders;
  }

  get isWoltEnabled(): boolean {
    return !!this.venue?.externalOrderProviders?.wolt?.enabled;
  }

  get isUberEatsEnabled(): boolean {
    return !!this.venue?.externalOrderProviders?.uberEats?.enabled;
  }

  get isJetEnabled(): boolean {
    return !!this.venue?.externalOrderProviders?.jet?.enabled;
  }

  public async syncAllFoodcards() {
    this.$startLoading('foodcard.sync.external');
    const foodcardPromises: Promise<any>[] = [];
    if (this.isWoltEnabled) {
      const woltApiService: WoltApiService = new WoltApiService();
      foodcardPromises.push(woltApiService.syncFoodcard({ venue: this.venue._id }));
    }
    if (this.isUberEatsEnabled) {
      const uberEatsApiService: UberEatsApiService = new UberEatsApiService();
      foodcardPromises.push(uberEatsApiService.syncFoodcard({ venue: this.venue._id }));
    }
    if (this.isJetEnabled) {
      const jetApiService: JetApiService = new JetApiService();
      foodcardPromises.push(jetApiService.syncFoodcard({ venue: this.venue._id }));
    }
    await Promise.all(foodcardPromises)
      .then(() => {
        this.notifySuccess('venue.form.externalOrderProviders.foodcardSyncStarted');
        this.$stopLoading('foodcard.sync.external');
      })
      .catch((e) => {
        this.$stopLoading('foodcard.sync.external');
        throw e;
      });
  }
}
