
import { Component } from 'vue-property-decorator';
import { InputType } from '@/enums/InputType';
import VFormBuilder from '@/components/shared/form/VFormBuilder.vue';
import StackedForm from '@/mixins/StackedForm';
import { mixins } from 'vue-class-component';

@Component({
  components: { VFormBuilder },
})
export default class DatevForm extends mixins(StackedForm) {
  public $refs!: {
    form: InstanceType<typeof VFormBuilder> & { form: any };
  };

  get items() {
    return [
      {
        name: 'costCenter',
        type: InputType.Text,
        label: 'venue.form.costCenter',
      },
      { name: 'account', type: InputType.Text, label: 'venue.form.account' },
      { name: 'offsetAccount', type: InputType.Text, label: 'venue.form.offsetAccount' },
    ];
  }
}
