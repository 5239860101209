
import { Component, Prop } from 'vue-property-decorator';
import VFormBuilder from '../../shared/form/VFormBuilder.vue';
import { InputType } from '../../../enums/InputType';
import StackedForm from '../../../mixins/StackedForm';
import { mixins } from 'vue-class-component';
import { convertEmptyToNull } from '@/util/helper';
import { OrderOffset } from '@/interfaces/models/OrderOffset';
import { CustomerGroup } from '@/enums/CustomerGroup';

interface OffsetData {
  standardOffsets?: OrderOffset;
  preorderOffsets: {
    inside: OrderOffset;
    takeAway: OrderOffset;
    delivery: OrderOffset;
  };
  terminalOffsets: {
    inside: OrderOffset;
    takeAway: OrderOffset;
  };
}

@Component({
  components: { VFormBuilder },
})
export default class OrderOffsetForm extends mixins(StackedForm) {
  @Prop({ type: Boolean, default: false }) public disableTerminal!: boolean;
  @Prop({ type: Boolean, default: false }) public disableStandard!: boolean;
  @Prop({ type: Boolean, default: false }) public disablePreorderInside!: boolean;
  @Prop({ type: Boolean, default: false }) public disablePreorderDelivery!: boolean;
  @Prop({ type: Boolean, default: false }) public disablePreorderTakeAway!: boolean;
  @Prop({ type: String, default: '' }) public customerGroup!: CustomerGroup;
  @Prop({ type: Boolean, default: false }) public readonly editing!: boolean;

  public reset() {
    if (!this.disableStandard) {
      this.$refs.standard.reset();
    }
    if (!this.disableTerminal) {
      this.$refs.terminalInside.reset();
      this.$refs.terminalTakeAway.reset();
    }

    if (this.$refs.preorderInside) {
      this.$refs.preorderInside.reset();
    }
    if (this.$refs.preorderTakeAway) {
      this.$refs.preorderTakeAway.reset();
    }
    if (this.$refs.preorderDelivery) {
      this.$refs.preorderDelivery.reset();
    }
  }

  public getData() {
    const data: any = {
      preorder: {
        inside: { ...convertEmptyToNull(this.$refs.preorderInside ? this.$refs.preorderInside.form : null) },
        takeAway: { ...convertEmptyToNull(this.$refs.preorderTakeAway ? this.$refs.preorderTakeAway.form : null) },
        delivery: { ...convertEmptyToNull(this.$refs.preorderDelivery ? this.$refs.preorderDelivery.form : null) },
      },
    };
    if (!this.disableStandard) {
      data.standard = { ...convertEmptyToNull(this.$refs.standard.form) };
    }
    if (!this.disableTerminal) {
      data.terminal = {
        inside: { ...convertEmptyToNull(this.$refs.terminalInside.form) },
        takeAway: { ...convertEmptyToNull(this.$refs.terminalTakeAway.form) },
      };
    }

    data.catering = {
      takeAway: { ...convertEmptyToNull(this.$refs.cateringTakeAway ? this.$refs.cateringTakeAway.form : null) },
      delivery: { ...convertEmptyToNull(this.$refs.cateringDelivery ? this.$refs.cateringDelivery.form : null) },
    };

    return { offsets: data };
  }

  get standardInitialValues() {
    // @ts-ignore
    if (this.initialValues && this.initialValues.offsets && this.initialValues.offsets.standard) {
      // @ts-ignore
      return this.initialValues.offsets.standard;
    }

    return {};
  }

  public getTerminalInitialValues(type: 'inside' | 'takeAway') {
    if (
      this.initialValues &&
      // @ts-ignore
      this.initialValues.offsets &&
      // @ts-ignore
      this.initialValues.offsets.terminal &&
      // @ts-ignore
      this.initialValues.offsets.terminal[type]
    ) {
      // @ts-ignore
      return this.initialValues.offsets.terminal[type];
    }

    return {};
  }

  public getPreorderInitialValues(type: 'inside' | 'delivery' | 'takeAway') {
    if (
      this.initialValues &&
      // @ts-ignore
      this.initialValues.offsets &&
      // @ts-ignore
      this.initialValues.offsets.preorder &&
      // @ts-ignore
      this.initialValues.offsets.preorder[type]
    ) {
      // @ts-ignore
      return this.initialValues.offsets.preorder[type];
    }

    return {};
  }

  public getCateringInitialValues(type: 'delivery' | 'takeAway') {
    if (
      this.initialValues &&
      // @ts-ignore
      this.initialValues.offsets &&
      // @ts-ignore
      this.initialValues.offsets.catering &&
      // @ts-ignore
      this.initialValues.offsets.catering[type]
    ) {
      // @ts-ignore
      return this.initialValues.offsets.catering[type];
    }

    return {};
  }

  public getDailyUnsnoozeTime() {
    const dailyUnsnoozeTime = this.$refs?.snoozing?.form?.dailyUnsnoozeTime;

    if (dailyUnsnoozeTime) {
      return { dailyUnsnoozeTime };
    }

    if (this.initialValues?.hasOwnProperty('dailyUnsnoozeTime')) {
      return { dailyUnsnoozeTime: null };
    }

    return {};
  }

  get offsets() {
    return [
      { name: 'first', label: 'venue.form.firstOrderOffset', type: InputType.Text, default: 0 },
      { name: 'offset', label: 'venue.form.orderOffset', type: InputType.Text, default: 0 },
      { name: 'last', label: 'venue.form.lastOrderOffset', type: InputType.Text, default: 0 },
    ];
  }

  get snoozing() {
    return [
      {
        name: 'dailyUnsnoozeTime',
        label: 'venue.form.dailyUnsnoozeTime',
        type: InputType.Text,
        rules: 'time',
        clearable: true,
      },
    ];
  }
}
