
import { Component, Vue } from 'vue-property-decorator';
import VOpeningHour from '@/components/shared/domain/VOpeningHour.vue';
import StackedForm from '@/mixins/StackedForm';
import { mixins } from 'vue-class-component';
import { cloneDeep } from '@/util/helper';
@Component({
  components: { VOpeningHour },
})
export default class CateringHoursForm extends mixins(StackedForm) {
  public validate() {
    // @ts-ignore
    return this.$refs.cateringDelivery.validate() && this.$refs.cateringTakeAway.validate();
  }

  public getData() {
    // @ts-ignore
    return {
      cateringHoursDelivery: this.$refs.cateringDelivery.getHours(),
      cateringHoursTakeAway: this.$refs.cateringTakeAway.getHours(),
    };
  }

  public mounted(): void {
    this.$nextTick(() => {
      if (this.initialValues) {
        // @ts-ignore
        if (this.initialValues.cateringHoursDelivery) {
          // @ts-ignore
          this.$refs.cateringDelivery?.setHours(this.initialValues.cateringHoursDelivery);
        }
        // @ts-ignore
        if (this.initialValues.cateringHoursTakeAway) {
          // @ts-ignore
          this.$refs.cateringTakeAway?.setHours(this.initialValues.cateringHoursTakeAway);
        }
      }
    });
  }
}
