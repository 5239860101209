
import { Component } from 'vue-property-decorator';
import { InputType } from '@/enums/InputType';
import VFormBuilder from '@/components/shared/form/VFormBuilder.vue';
import StackedForm from '@/mixins/StackedForm';
import { mixins } from 'vue-class-component';

@Component({
  components: { VFormBuilder },
})
export default class OrderForm extends mixins(StackedForm) {
  public $refs!: {
    form: InstanceType<typeof VFormBuilder> & { form: any };
    stationsForm: InstanceType<typeof VFormBuilder> & { form: any };
  };

  public addedStations: string[] = [];

  get orderItems() {
    return [
      { name: 'standardOrders', type: InputType.Checkbox, default: false, label: 'venue.form.standardOrders' },
      { name: 'virtualOrders', type: InputType.Checkbox, default: false, label: 'venue.form.virtualOrders' },
      {
        name: 'autoAccept',
        type: InputType.Select,
        default: 'none',
        items: this.orderStatusTypes,
        label: 'venue.form.autoAccept',
      },
      {
        name: 'autoAcceptTerminal',
        default: 'none',
        type: InputType.Select,
        items: this.orderStatusTypes,
        label: 'venue.form.autoAcceptTerminal',
      },
      {
        name: 'autoReset',
        type: InputType.Select,
        default: 'none',
        items: this.autoResetTypes,
        label: 'venue.form.autoReset',
      },
      {
        name: 'orderTimeoutEnabled',
        type: InputType.Checkbox,
        default: false,
        label: 'venue.form.orderTimeoutEnabled',
      },
      {
        name: 'secondaryCodeEnabled',
        type: InputType.Checkbox,
        default: false,
        label: 'venue.form.secondaryCodeEnabled',
      },
      {
        name: 'orderTimeout',
        type: InputType.Text,
        label: 'venue.form.orderTimeout',
        rules: 'integer',
        disabled: this.orderTimeoutSettingsDisabledCallback,
      },
    ];
  }

  get formItems() {
    return [{ name: 'station', type: InputType.Text, label: 'venue.station.placeholder' }];
  }

  get orderStatusTypes() {
    return [
      { value: 'none', text: this.$t('orderStatus.none') },
      { value: 'created', text: this.$t('orderStatus.created') },
      { value: 'in_preparation', text: this.$t('orderStatus.in_preparation') },
      { value: 'ready', text: this.$t('orderStatus.ready') },
      { value: 'done', text: this.$t('orderStatus.done') },
      { value: 'in_payment', text: this.$t('orderStatus.in_payment') },
      { value: 'awaiting_confirmation', text: this.$t('orderStatus.awaiting_confirmation') },
    ];
  }

  get autoResetTypes() {
    return [
      { value: 'none', text: this.$t('autoReset.none') },
      { value: 'morning', text: this.$t('autoReset.morning') },
      { value: 'noon', text: this.$t('autoReset.noon') },
      { value: 'evening', text: this.$t('autoReset.evening') },
      { value: 'midnight', text: this.$t('autoReset.midnight') },
    ];
  }

  public created() {
    // @ts-ignore
    this.addedStations = _.cloneDeep(this.initialValues ? this.initialValues.stations : []);
  }

  public addStation() {
    this.$refs.stationsForm.validate().then((result: boolean) => {
      if (
        result &&
        !this.addedStations.includes(this.$refs.stationsForm.form.station) &&
        this.$refs.stationsForm.form.station.length > 0
      ) {
        this.addedStations.push(this.$refs.stationsForm.form.station);
      }
      this.$refs.stationsForm.reset();
      this.$refs.stationsForm.clearErrors();
    });
  }

  public removeStation(index: number) {
    this.$confirm(this.$t('venue.station.deleteStation') as string).then((res: boolean) => {
      if (res) {
        this.addedStations.splice(index, 1);
        this.$refs.stationsForm.clearErrors();
      }
    });
  }

  public getData() {
    return {
      ...this.$refs.form.form,
      ...{ stations: this.addedStations },
    };
  }

  public orderTimeoutSettingsDisabledCallback(form: { orderTimeoutEnabled: boolean }) {
    return !form.orderTimeoutEnabled;
  }
}
