import * as mustache from 'mustache';
import VueI18n, { Locale } from 'vue-i18n';

export interface ImprintData {
  company: string;
  street: string;
  postalCode: string;
  city: string;
  email: string;
  vatNumber: string;
  registerCourt: string;
  registerNumber: string;
  phone: string;
  fax: string;
  chiefExecutives: string;
}

export interface PrivacyData {
  company: string;
  street: string;
  postalCode: string;
  city: string;
  email: string;
  phone: string;
  fax: string;
}

function renderTerms(t: typeof VueI18n.prototype.t, locale: Locale): string {
  return mustache.render(t('venue.legal.termsHtml', locale).toString(), {});
}

function renderImprint(t: typeof VueI18n.prototype.t, locale: Locale, data: ImprintData): string {
  return mustache.render(t('venue.legal.imprintHtml', locale).toString(), data);
}

function renderPrivacy(t: typeof VueI18n.prototype.t, locale: Locale, data: PrivacyData): string {
  return mustache.render(t('venue.legal.privacyHtml', locale).toString(), data);
}

export const templates: {
  terms: (t: typeof VueI18n.prototype.t, locale: Locale) => string;
  imprint: (t: typeof VueI18n.prototype.t, locale: Locale, data: ImprintData) => string;
  privacy: (t: typeof VueI18n.prototype.t, locale: Locale, data: PrivacyData) => string;
} = {
  terms: renderTerms,
  imprint: renderImprint,
  privacy: renderPrivacy,
};

export { templates as default };
