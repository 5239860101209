
import { Component, Vue } from 'vue-property-decorator';
import { mixins } from 'vue-class-component';
import { InputType } from '@/enums/InputType';
import VFormBuilder from '@/components/shared/form/VFormBuilder.vue';
import StackedForm from '@/mixins/StackedForm';
import { DeliveryProviders } from '@/interfaces/models/DeliveryProviders';
import { Permission } from '@/enums/Permission';

@Component({
  components: { VFormBuilder },
})
export default class DeliverProvidersForm extends mixins(StackedForm) {
  public $refs!: {
    form: InstanceType<typeof VFormBuilder> & {
      form: any;
    };
  };

  get deliveryProviders() {
    const values: DeliveryProviders = { ...this.initialValues } as DeliveryProviders;

    return {
      orderLordActive: !!(values.orderLord && values.orderLord.orderLordActive),
      orderLordEnabled: !!(values.orderLord && values.orderLord.orderLordEnabled),
      orderLordToken: values.orderLord && values.orderLord.orderLordToken,
      orderLordPassword: values.orderLord && values.orderLord.orderLordPassword,
      rushhourIntegration: values.rushHour && values.rushHour.rushhourIntegration,
      rushhourToken: values.rushHour && values.rushHour.rushhourToken,
    };
  }

  get formItems() {
    return [
      {
        name: 'orderLordEnabled',
        type: InputType.Checkbox,
        label: 'venue.form.deliveryProviders.orderLordEnabled',
        visible: this.$can(Permission.DELIVERY_PROVIDER_ENABLING),
      },
      {
        name: 'orderLordActive',
        type: InputType.Checkbox,
        label: 'venue.form.deliveryProviders.orderLordActive',
        visible: this.$can(Permission.DELIVERY_PROVIDER_ACTIVATION),
      },
      { name: 'orderLordToken', type: InputType.Text, label: 'venue.form.deliveryProviders.orderLordToken' },
      { name: 'orderLordPassword', type: InputType.Text, label: 'venue.form.deliveryProviders.orderLordPassword' },
      {
        name: 'rushhourIntegration',
        type: InputType.Checkbox,
        label: 'venue.form.deliveryProviders.rushhourIntegration',
        visible: this.$can(Permission.DELIVERY_PROVIDER_ACTIVATION),
      },
      { name: 'rushhourToken', type: InputType.Text, label: 'venue.form.deliveryProviders.rushhourToken' },
    ];
  }

  public getData(): { deliveryProviders: DeliveryProviders } {
    const data: any = { ...this.$refs.form.form };

    return {
      // use value of orderLordEnabled/rushhourIntegration for now
      deliveryProviders: {
        useDeliveryProvider: data.orderLordEnabled || data.rushhourIntegration,
        orderLord: {
          orderLordActive: data.orderLordActive,
          orderLordEnabled: data.orderLordEnabled,
          orderLordToken: data.orderLordToken,
          orderLordPassword: data.orderLordPassword,
        },
        rushHour: {
          rushhourIntegration: data.rushhourIntegration,
          rushhourToken: data.rushhourToken,
        },
      },
    };
  }
}
