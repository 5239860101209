import { Component, Watch } from 'vue-property-decorator';
import { namespace } from 'vuex-class';
import { Venue } from '@/interfaces/models/Venue';
import { mixins } from 'vue-class-component';
import Editable from '@/mixins/Editable';
import { Permission } from '@/enums/Permission';
import PaymentApiService from '@/api/http/PaymentApiService';
import { AxiosResponse } from 'axios';
import { PayPalAuthResponse, PayPalLink } from '@/interfaces/paypal/PayPalAuthResponse';
import Notification from '@/mixins/Notification';
import { LegalDocumentType } from '@/enums/LegalDocumentType';

const venue = namespace('venue');

@Component
export class EditVenue extends mixins(Editable, Notification) {
  get LegalDocumentType() {
    return LegalDocumentType;
  }

  get venue() {
    if (this.active && this.editing && !this.$isLoading('venue')) {
      return this.active;
    }

    return null;
  }

  get paypalConnected() {
    return this.venue && this.venue.ppEmailConfirmed === true && this.venue.ppPaymentsReceivable === true;
  }

  get lightspeedAuthorized() {
    return this.checkoutIntegration && !!this.checkoutIntegration?.lightspeed?.accessToken;
  }

  get isLightspeedAuthorizable() {
    return this.checkoutIntegration && !!this.checkoutIntegration?.lightspeed?.businessId;
  }

  get lightspeedAuthorizationUrl() {
    if (!this.venue) {
      return '';
    }
    return `${process.env.VUE_APP_LIGHTSPEED_BASE_URL}/oauth/authorize?client_id=${process.env.VUE_APP_LIGHTSPEED_CLIENT_ID}&response_type=code&state=${this.venue._id}&redirect_uri=${process.env.VUE_APP_API_URL}/v1/lightspeed/oauth/token`;
  }

  get title() {
    return this.editing ? 'venue.edit' : 'venue.create';
  }

  get canAuthPayPal() {
    return this.$can(Permission.VENUE_PAYPAL_AUTHORIZE) && this.editing && this.venue;
  }

  get canCheckPayPalOnboarding() {
    return (
      this.$can(Permission.VENUE_PAYPAL_CHECK) &&
      this.editing &&
      this.venue &&
      this.venue.ppEmail &&
      this.venue.ppMerchantId
    );
  }
  @venue.State('active') public active!: Venue;
  @venue.State('checkoutIntegration') public checkoutIntegration!: any;
  @venue.Action('showSimple') public show!: any;
  @venue.Action('showPayment') public showPayment!: any;
  @venue.Action('store') public store!: any;
  @venue.Action('update') public update!: any;

  @venue.Action('setCheckoutIntegration') public setCheckoutIntegration!: any;
  @venue.Action('resetCheckoutIntegration') public resetCheckoutIntegration!: any;
  @venue.Action('uploadImage') public uploadImage!: any;
  @venue.Action('uploadNutritionTable') public uploadNutritionTable!: any;
  @venue.Action('uploadSelfCertification') public uploadSelfCertification!: any;
  @venue.Action('uploadTermsAndConditions') public uploadTermsAndConditions!: any;
  @venue.Action('uploadExternalDeliveryIcon') public uploadExternalDeliveryIcon!: any;
  @venue.Action('uploadImprint') public uploadImprint!: any;
  @venue.Action('uploadPrivacy') public uploadPrivacy!: any;
  @venue.Action('setDelfi') public setDelfi!: any;
  @venue.Action('updateDelfi') public updateDelfi!: any;

  public basicData: Partial<Venue> = {
    email: '',
    vatNumber: '',
    vatNumberGermany: '',
    invoiceReceiver: {
      fullName: '',
      street: '',
      postalCode: '',
      city: '',
      number: '',
    },
  };

  // public paymentCredentialFields: string[] = [
  //   'provisionRate',
  //   'ccSecretKey',
  //   'ccMerchantId',
  //   'sepaMerchantId',
  //   'sepaSecretKey',
  //   'sepaPaymentMandat',
  //   'sepaMerchantName',
  //   'sepaCreditorId',
  //   'sbMerchantId',
  //   'cashEnabled',
  //   'posEnabled',
  //   'apMerchantId',
  //   'gpMerchantId',
  //   'vrAccessToken',
  // ];

  public onBasicDataChange(basicData: Partial<Venue>) {
    this.basicData = { ...basicData };
  }

  public async mounted(): Promise<void> {
    if (this.editing) {
      this.$startLoading('venue');
      this.$startLoading('venue.payment');
      await this.show({ id: this.id });
      this.$stopLoading('venue');
      if (this.$can(Permission.VENUE_PAYMENT)) {
        await this.showPayment({ id: this.id });
      }

      this.resetCheckoutIntegration();
      this.$stopLoading('venue.payment');
    } else {
      this.$stopLoading('venue');
    }
  }

  public created() {
    if (!this.active) {
      this.$startLoading('venue');
    }
  }

  public checkPayPalOnboarding() {
    const api: PaymentApiService = new PaymentApiService();
    api
      .checkPayPalOnboardingStatus({ venue: this.venue!._id })
      .then(() => {
        this.notifyInfo('venue.notification.checkPayPalOnboardingSuccess');
      })
      .catch(() => {
        this.notifyError('venue.notification.checkPayPalOnboardingError');
      });
  }

  public authorizePayPal() {
    this.$startLoading('authorizePayPal');
    const api: PaymentApiService = new PaymentApiService();
    api
      .authorizePayPal({ venue: this.venue!._id })
      .then((res: AxiosResponse<PayPalAuthResponse>) => {
        if (res.data.links) {
          const authLink: PayPalLink = res.data.links.find((link: PayPalLink) => link.rel === 'action_url')!;
          if (authLink) {
            window.open(authLink.href, '_blank');
          }
        }
      })
      .catch(() => {
        this.notifyError('venue.notification.registerPayPalError');
      })
      .finally(() => {
        this.$stopLoading('authorizePayPal');
      });
  }

  public sanitize(data: Partial<Venue>) {
    if (!data.currencySymbol) {
      data.currencySymbol = '€';
    }
    if (data.legal) {
      if (data.legal && data.legal.terms instanceof File) {
        // @ts-ignore
        delete data.legal.terms;
      }
      if (data.legal && data.legal.privacy instanceof File) {
        // @ts-ignore
        delete data.legal.privacy;
      }
      if (data.legal && data.legal.imprint instanceof File) {
        // @ts-ignore
        delete data.legal.imprint;
      }
    }

    // for (const field of this.paymentCredentialFields) {
    //   // @ts-ignore
    //   if (data[field] === '') {
    //     // @ts-ignore
    //     delete data[field];
    //   }
    // }

    return data;
  }

  public sanitizeConf(data: any) {
    if (data && data.hasOwnProperty('scheduleSync')) {
      delete data.scheduleSync;
    }

    if (data && data.lastResult) {
      delete data.lastResult;
    }

    return data;
  }

  @Watch('venue')
  public onVenueChange() {
    if (this.venue) {
      this.basicData = { ...this.venue };
    }
  }
}
