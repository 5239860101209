import axios, { AxiosInstance, AxiosPromise, AxiosResponse } from 'axios';
import { Localization } from '@/interfaces/models/Localization';

export interface HereAddress {
  country: string;
  countryName: string;
  countryCode: string;
  state: string;
  county: string;
  city: string;
  district: string;
  street: string;
  houseNumber: string;
  postalCode: string;
}

export interface HereGeocode {
  title: string;
  id: string;
  resultType: string;
  houseNumberType: string;
  address: HereAddress;
  position: Location;
  access: Location;
}

export interface Location {
  lat: number;
  lng: number;
}

export interface HereSuggestion {
  label: string;
  language: string;
  countryCode: string;
  locationId: string;
  address: HereAddress;
}

export interface Address {
  city: string | Localization;
  street: string;
  number: string;
  postalCode: string;
}

export class HereApiService {
  public static lookup(address: Address): AxiosPromise<{ items: HereGeocode[] }> {
    const axiosInstance: AxiosInstance = axios.create({});

    return axiosInstance.get('https://geocode.search.hereapi.com/v1/geocode', {
      params: {
        apiKey: process.env.VUE_APP_HERE_API_KEY,
        appId: process.env.VUE_APP_HERE_API_ID,
        in: 'countryCode:DEU,AUT,FRA,LUX',
        q: `${address.number}+${address.street}+${
          address.city && (address.city as Localization).de ? (address.city as Localization).de : address.city
        }+${address.postalCode}`,
      },
    });
  }

  public static partialLookup(address: Partial<Address>): AxiosPromise<{ items: HereGeocode[] }> {
    const axiosInstance: AxiosInstance = axios.create({});

    const query: string = Object.values(address).join('+');

    return axiosInstance.get('https://geocode.search.hereapi.com/v1/geocode', {
      params: {
        apiKey: process.env.VUE_APP_HERE_API_KEY,
        appId: process.env.VUE_APP_HERE_API_ID,
        in: 'countryCode:DEU,AUT,FRA,LUX',
        q: query,
      },
    });
  }

  public static getSuggestions(address: Partial<Address>): AxiosPromise<{ items: HereSuggestion[] }> {
    const axiosInstance: AxiosInstance = axios.create({});

    const query: string = Object.values(address).join('+');

    return axiosInstance.get('https://autosuggest.search.hereapi.com/v1/autosuggest', {
      params: {
        apiKey: process.env.VUE_APP_HERE_API_KEY,
        appId: process.env.VUE_APP_HERE_API_ID,
        resultType: 'locality',
        in: 'countryCode:DEU,AUT,FRA,LUX',
        q: query,
      },
    });
  }

  public static async getGeoCoordinates(address: Address): Promise<Location | null> {
    const res: AxiosResponse<{ items: HereGeocode[] }> = await HereApiService.lookup(address);
    const result: HereGeocode = res.data.items[0];

    if (result) {
      return result.position;
    }

    return null;
  }
}
