import { Component, Prop, Vue } from 'vue-property-decorator';
import { index } from '@/util/helper';

@Component
export default class StackedForm extends Vue {
  @Prop({ type: Object }) public initialValues!: object;

  public $refs!: any;

  public async validate() {
    const validatable: any = [];
    const refKeys: string[] = Object.keys(this.$refs);
    for (const key of refKeys) {
      if (
        this.$refs[key] &&
        this.$refs[key].hasOwnProperty('validate') &&
        typeof this.$refs[key].validate === 'function'
      ) {
        validatable.push(await this.$refs[key].validate());
      }
    }

    return validatable;
  }

  public async validateRefs(refs: any): Promise<{ refKey: any; result: boolean }[]> {
    if (!refs || refs.length === 0) return [];

    const validatable: { refKey: any; result: boolean }[] = [];
    for (const [index, ref] of refs.entries()) {
      validatable.push({ refKey: index, result: await ref.validate() });
    }

    return validatable;
  }

  public async validateWithKeys() {
    const validatable: any = [];
    const refKeys: string[] = Object.keys(this.$refs);
    for (const key of refKeys) {
      if (
        this.$refs[key] &&
        this.$refs[key].hasOwnProperty('validate') &&
        typeof this.$refs[key].validate === 'function'
      ) {
        validatable.push({ refKey: key, result: await this.$refs[key].validate() });
      }
    }

    return validatable;
  }

  public getInitialValue(path: string): any {
    return index(this.initialValues, path);
  }

  public getData() {
    return {
      ...this.$refs.form.form,
    };
  }

  public setData(data: object) {
    this.$refs.form.form = data;
  }

  public isValid(result: any) {
    for (const comp of result) {
      if (Array.isArray(comp)) {
        for (const formResult of comp) {
          if (!formResult) {
            return false;
          }
        }
      } else {
        if (!comp) {
          return false;
        }
      }
    }

    return true;
  }

  public isValidWithKey(res: any) {
    for (const comp of res) {
      if (Array.isArray(comp.result)) {
        for (const formResult of comp.result) {
          if (!formResult) {
            return { refKey: comp.refKey, result: false };
          }
        }
      } else {
        if (!comp || !comp.result) {
          return { refKey: comp.refKey, result: false };
        }
      }
    }

    return { refKey: null, result: true };
  }

  public async validateByKey(key: string) {
    return await this.$refs?.form.validate(key);
  }
}
