import { Component, Vue } from 'vue-property-decorator';

@Component
export default class LegalForm extends Vue {
  get legalForms() {
    return [
      { text: this.$t('legalForms.ag'), value: 'ag' },
      { text: this.$t('legalForms.gmbh'), value: 'gmbh' },
      { text: this.$t('legalForms.ltd'), value: 'ltd' },
      { text: this.$t('legalForms.kg'), value: 'kg' },
      { text: this.$t('legalForms.pe'), value: 'pe' },
      { text: this.$t('legalForms.other'), value: 'other' },
      { text: this.$t('legalForms.sa'), value: 'sa' },
      { text: this.$t('legalForms.sarl'), value: 'sarl' },
      { text: this.$t('legalForms.eurl'), value: 'eurl' },
      { text: this.$t('legalForms.sas'), value: 'sas' },
      { text: this.$t('legalForms.autres'), value: 'autres' },
    ];
  }
}
