
import { Component, Prop } from 'vue-property-decorator';
import { InputType } from '@/enums/InputType';
import VFormBuilder from '@/components/shared/form/VFormBuilder.vue';
import StackedForm from '@/mixins/StackedForm';
import { mixins } from 'vue-class-component';
import { LegalDocumentType } from '@/enums/LegalDocumentType';
import templates from '@/util/legalTemplates';
import { Venue } from '@/interfaces/models/Venue';
import DocumentLink from '@/components/venue/form/DocumentLink.vue';
import { FormItem } from '@/interfaces/components/FormItem';
import VueI18n from 'vue-i18n';

@Component({
  components: { DocumentLink, VFormBuilder },
})
export default class DocumentForm extends mixins(StackedForm) {
  get documentItems() {
    if (this.selectedType === 0) {
      return [
        {
          name: 'terms',
          type: InputType.RichText,
          label: 'venue.form.terms',
          open: false,
          default: this.getDocumentDefault('terms'),
        },
        {
          name: 'imprint',
          type: InputType.RichText,
          label: 'venue.form.imprint',
          open: false,
          default: this.getDocumentDefault('imprint'),
        },
        {
          name: 'privacy',
          type: InputType.RichText,
          label: 'venue.form.privacy',
          open: false,
          default: this.getDocumentDefault('privacy'),
        },
      ];
    }

    return [
      { name: 'terms', type: InputType.File, label: 'venue.form.terms', default: null, accept: 'application/pdf' },
      { name: 'imprint', type: InputType.File, label: 'venue.form.imprint', default: null, accept: 'application/pdf' },
      { name: 'privacy', type: InputType.File, label: 'venue.form.privacy', default: null, accept: 'application/pdf' },
    ];
  }

  get templateData() {
    // @ts-ignore
    if (this.initialValues && this.initialValues.venue) {
      // @ts-ignore
      const venue: Partial<Venue> = this.initialValues.venue;
      return {
        ...venue.invoiceReceiver,
        street: `${venue.invoiceReceiver!.street} ${venue.invoiceReceiver!.number}`,
        company: venue.invoiceReceiver!.fullName,
        email: venue.email,
        vatNumber: venue.vatNumber,
      };
    }

    return {};
  }

  get templateItems() {
    return [
      { name: 'company', type: InputType.Text, label: 'venue.form.fullName' },
      { name: 'street', type: InputType.Text, label: 'venue.form.streetAddress' },
      { name: 'postalCode', type: InputType.Text, label: 'venue.form.postalCode' },
      { name: 'city', type: InputType.Text, label: 'venue.form.city' },
      { name: 'email', type: InputType.Text, label: 'venue.form.email' },
      { name: 'vatNumber', type: InputType.Text, label: 'venue.form.vatNumber' },
      {
        name: 'registerCourt',
        type: InputType.Text,
        label: 'venue.form.registerCourt',
        visible: !this.disableRegister,
      },
      {
        name: 'registerNumber',
        type: InputType.Text,
        label: 'venue.form.registerNumber',
        visible: !this.disableRegister,
      },
      { name: 'phone', type: InputType.Text, label: 'venue.form.phone' },
      { name: 'fax', type: InputType.Text, label: 'venue.form.fax' },
      { name: 'chiefExecutives', type: InputType.Text, label: 'venue.form.chiefExecutives' },
    ];
  }

  @Prop({ type: Boolean, default: false }) public disablePdf!: boolean;
  @Prop({ type: Boolean, default: false }) public flat!: boolean;
  @Prop({ type: Boolean, default: false }) public disableRegister!: boolean;
  @Prop({ type: Boolean, default: false }) public editing!: boolean;

  public $refs!: {
    form: InstanceType<typeof VFormBuilder> & {
      form: any;
      getFilesToRemove: () => string[];
      setData: (form: any) => void;
    };
    templateForm: InstanceType<typeof VFormBuilder> & { form: any };
  };

  // @ts-ignore
  protected selectedType: number = this.initialValues && this.initialValues.type === LegalDocumentType.PDF ? 1 : 0;
  protected useTemplate: boolean = false;

  public isEmptyOrStorageLink(str: string): boolean {
    return !str || str.startsWith('https://storage.googleapis.com');
  }

  public getDocumentDefault(key: string): string | null {
    if (!this.initialValues || !this.initialValues.hasOwnProperty(key)) {
      return '';
    }
    // @ts-ignore
    if (this.selectedType === 1) {
      return null;
      // @ts-ignore
    } else if (this.selectedType === 0 && !this.isEmptyOrStorageLink(this.initialValues[key])) {
      // @ts-ignore
      return this.initialValues[key];
    }

    return '';
  }

  public getData() {
    if (!this.initialValues) {
      return {
        legal: {
          imprint: null,
          terms: null,
          privacy: null,
          type: LegalDocumentType.HTML,
        },
      };
    }
    const data: any = { ...this.$refs.form.form };

    const filesToRemove: string[] = this.$refs.form.getFilesToRemove();

    data.type = this.selectedType === 0 ? LegalDocumentType.HTML : LegalDocumentType.PDF;

    if (data.type === LegalDocumentType.PDF) {
      // @ts-ignore
      if (data.terms === null && this.isEmptyOrStorageLink(this.initialValues.terms)) {
        // @ts-ignore
        data.terms = this.initialValues.terms;
      }
      // @ts-ignore
      if (data.imprint === null && this.isEmptyOrStorageLink(this.initialValues.imprint)) {
        // @ts-ignore
        data.imprint = this.initialValues.imprint;
      }
      // @ts-ignore
      if (data.privacy === null && this.isEmptyOrStorageLink(this.initialValues.privacy)) {
        // @ts-ignore
        data.privacy = this.initialValues.privacy;
      }
    }

    // @ts-ignore
    if (this.initialValues) {
      // @ts-ignore
      if (this.initialValues.terms && filesToRemove.indexOf('terms') > -1) {
        data.terms = null;
      }
      // @ts-ignore
      if (this.initialValues.imprint && filesToRemove.indexOf('imprint') > -1) {
        data.imprint = null;
      }
      // @ts-ignore
      if (this.initialValues.privacy && filesToRemove.indexOf('privacy') > -1) {
        data.privacy = null;
      }
    }

    return {
      legal: {
        ...data,
      },
    };
  }

  protected selectionChange(selection: number) {
    this.selectedType = selection;
    this.$refs.form.setData({
      terms: this.getDocumentDefault('terms'),
      imprint: this.getDocumentDefault('imprint'),
      privacy: this.getDocumentDefault('privacy'),
    });
  }

  protected applyTemplateValues() {
    if (this.$refs.templateForm.form) {
      this.selectedType = 0;
      const data: any = { ...this.$refs.templateForm.form };
      const commonData: any = {
        company: data.company,
        street: data.street,
        postalCode: data.postalCode,
        city: data.city,
        email: data.email,
        phone: data.phone,
        fax: data.fax,
      };

      const imprintData: any = {
        ...commonData,
        vatNumber: data.vatNumber,
        registerCourt: data.registerCourt,
        registerNumber: data.registerNumber,
        chiefExecutives: data.chiefExecutives,
      };

      if (this.disableRegister) {
        delete imprintData.registerCourt;
        delete imprintData.registerNumber;
      }

      const t: typeof VueI18n.prototype.t = this.$t.bind(this);
      const terms: string = templates.terms(t, this.$i18n.locale);
      const imprint: string = templates.imprint(t, this.$i18n.locale, imprintData);
      const privacy: string = templates.privacy(t, this.$i18n.locale, commonData);

      this.$refs.form.setData({ terms, imprint, privacy });
      // @ts-ignore
      this.$refs.form.items.forEach((item: FormItem) => (item.open = true));
      this.useTemplate = false;
    }
  }
}
