
import { Component } from 'vue-property-decorator';
import { InputType } from '@/enums/InputType';
import VFormBuilder from '@/components/shared/form/VFormBuilder.vue';
import StackedForm from '@/mixins/StackedForm';
import { mixins } from 'vue-class-component';

@Component({
  components: { VFormBuilder },
})
export default class LoyaltyForm extends mixins(StackedForm) {
  public $refs!: {
    form: InstanceType<typeof VFormBuilder> & { form: any };
  };

  get items() {
    return [
      {
        name: 'piggyLoyaltyEnabled',
        type: InputType.Checkbox,
        label: 'venue.form.piggyLoyaltyEnabled',
        rules: 'required',
      },
    ];
  }
}
