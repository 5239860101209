
import { Component, Prop } from 'vue-property-decorator';
import { InputType } from '@/enums/InputType';
import { SelectItem } from '@/interfaces/components/SelectItem';
import VFormBuilder from '@/components/shared/form/VFormBuilder.vue';
import StackedForm from '@/mixins/StackedForm';
import { mixins } from 'vue-class-component';
import { convertEmptyToNull } from '@/util/helper';

@Component({
  components: { VFormBuilder },
})
export default class SanifairForm extends mixins(StackedForm) {
  public $refs!: {
    form: InstanceType<typeof VFormBuilder> & { form: any };
  };

  get items() {
    return [
      { name: 'sanifairEnabled', type: InputType.Checkbox, label: 'venue.form.sanifairEnabled', default: false },
      { name: 'sanifairLocationId', type: InputType.Text, label: 'venue.form.sanifairLocationId' },
      { name: 'sanifairDeviceId', type: InputType.Text, label: 'venue.form.sanifairDeviceId' },
    ];
  }
}
