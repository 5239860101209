
import { Component, Emit, Prop, Watch, Vue } from 'vue-property-decorator';
import { ColumnHeader } from '@/interfaces/components/ColumnHeader';
import { Page } from '@/interfaces/api/Page';
import { VCol, VListItem, VTextField } from 'vuetify/lib';
import { Article } from '@/interfaces/models/Article';
import { ExternalOrderProvidersArticleSettings } from '@/interfaces/models/ExternalOrderProvidersArticleSettings';

@Component
export default class VTable extends Vue {
  get actions() {
    return this.editable || this.deleteable || this.customActions;
  }

  get pageCount() {
    /* if (Number.isInteger(this.limit)) {
      this.rowsPerPage = this.limit;
    }*/

    if (this.pagination) {
      return Math.ceil(this.total / this.rowsPerPage);
    }

    return 0;
  }

  @Prop({ type: Array, required: true }) public items!: any;
  @Prop({ type: Array, required: true }) public headers!: ColumnHeader[];
  @Prop({ type: String, default: '' }) public search!: string;
  @Prop({ type: Boolean, default: true }) public pagination!: boolean;
  @Prop({ type: Boolean, default: false }) public selectable!: boolean;
  @Prop({ type: Number, required: false }) public total!: number;
  @Prop({ type: [Function, Boolean], default: true }) public editable!: boolean | ((item: any) => boolean);
  @Prop({ type: [Function, Boolean], default: false }) public deleteable!: boolean | ((item: any) => boolean);
  @Prop({ type: Boolean, default: false }) public customActions!: boolean;
  @Prop({ type: Boolean, default: false }) public loading!: boolean;
  @Prop({ type: Boolean, default: false }) public expandable!: boolean;
  @Prop({ type: Boolean, default: false }) public showPerPagePicker!: boolean;
  @Prop({ type: Array, default: () => [10, 20, 50], required: false }) public itemsPerPageOptions!: number[];
  @Prop({ type: Number, default: () => 10 }) public limit!: number;
  @Prop({ type: Boolean, default: false }) public withoutRouting!: boolean;
  @Prop({ type: Boolean, default: false }) public directRoute!: boolean;
  @Prop({ type: String, default: '' }) public directRoutPath!: string;
  @Prop({ type: Object, default: () => ({}) }) public directRoutParams!: { [key: string]: string };
  @Prop({ type: Object, default: () => ({}) }) public directRoutQuery!: { [key: string]: string };
  @Prop({ type: Boolean, default: false }) public jumpTo!: boolean;

  public rowsPerPage: number = 10;

  public page: number = 1;

  public jumpToPage: number | null = null;

  protected selected: any[] = [];

  @Emit('page')
  @Watch('page')
  public onPageChange(): Page {
    if (this.$route.query.page && parseInt(this.$route.query.page as string, 10) !== this.page) {
      // @ts-ignore
      this.$router.push({ query: { page: this.page, limit: this.rowsPerPage } }).catch((e) => {
        throw Error(`${e}`);
      });
    }

    return { page: this.page, limit: this.rowsPerPage };
  }

  public mounted(): void {
    let page: number = 1;
    let limit: number = this.limit;
    if (this.$route.query.page) {
      page = parseInt(this.$route.query.page as string, 10);
    }
    if (this.$route.query.limit) {
      limit = parseInt(this.$route.query.limit as string, 10);
    }
    if (page !== this.page || limit !== this.rowsPerPage) {
      this.page = page;
      this.rowsPerPage = limit;
      this.$emit('page', { page: this.page, limit: this.rowsPerPage });
    } else {
      if (!this.withoutRouting) {
        // @ts-ignore
        this.$router.push({ query: { page: this.page, limit: this.rowsPerPage } }).catch(() => {
          throw Error('route on VTable');
        });
      }
    }
  }

  public isEditable(item: any) {
    if (typeof this.editable === 'function') {
      return this.editable(item);
    }

    return this.editable;
  }

  public mayDelete(item: any) {
    this.$confirm().then((res: boolean) => {
      if (res) {
        this.$emit('delete', item);
      }
    });
  }

  public resetPagination() {
    this.page = 1;
    this.rowsPerPage = 10;
  }

  public isDeleteable(item: any) {
    if (typeof this.deleteable === 'function') {
      return this.deleteable(item);
    }

    return this.deleteable;
  }

  public isSelected(item: any) {
    return this.selected.indexOf(item) > -1;
  }

  public handleCellClick(header: ColumnHeader, item: any) {
    if (!header.editable || !this.editable) {
      return;
    }

    this.$emit('editSpecificCell', { header, item });
  }

  getItemValue(item: any, valuePath: string) {
    const pathParts: string[] = valuePath.split('.');
    let value = item;

    for (let part of pathParts) {
      value = value ? value[part] : 0;
    }

    return value;
  }

  getExternalOrderPrice(item: Article, valuePath: string): any {
    if (!item.externalOrderProviderSettings) return 0;

    const externalOrderProvider: ExternalOrderProvidersArticleSettings | undefined =
      item.externalOrderProviderSettings.find((i) => i.provider === valuePath);

    return externalOrderProvider?.price ? externalOrderProvider.price : 0;
  }

  public handleAsset(item: any, header: any) {
    this.$emit('uploadAsset', {
      venue: item,
      asset: header.value,
    });
  }

  public showQr(url: string) {
    window.open(url, '_blank');
  }

  public toggleSelect(item: any) {
    if (this.isSelected(item)) {
      const index: number = this.selected.indexOf(item);
      this.selected.splice(index, 1);
    } else {
      this.selected.push(item);
    }
  }

  public paramsForDirectRoute(item: any): { [key: string]: string } {
    const params: { [key: string]: string } = {};
    Object.keys(this.directRoutParams).map((key: string) => {
      params[key] = item[this.directRoutParams[key]];
    });
    return params;
  }

  public jumpToPageHandler() {
    if (Number(this.jumpToPage) > 0 && Number(this.jumpToPage) < this.pageCount) {
      this.page = Number(this.jumpToPage);
      this.jumpToPage = null;
    }
  }

  public getSelected(): any[] {
    return this.selected;
  }

  public get isSelectedAll() {
    return this.selected.length === this.items.length;
  }

  public resetSelected() {
    this.selected = [];
  }

  public onSelectAll(event: boolean) {
    if (this.selected.length !== this.items.length) {
      this.selected = [...this.items];
    } else {
      this.selected = [];
    }
  }

  @Emit('rows')
  @Watch('rowsPerPage')
  public onChangeRowsPerPage() {
    if (this.$route.query.limit && parseInt(this.$route.query.limit as string, 10) !== this.rowsPerPage) {
      this.page = 1;
      // @ts-ignore
      this.$router.push({ query: { page: 1, limit: this.rowsPerPage } });
    }
    this.page = 1;
    return { page: 1, limit: this.rowsPerPage };
  }
}
