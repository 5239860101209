var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('VRow',[_c('VCol',{attrs:{"cols":"6"}},[_c('VList',[_c('VListItem',[_c('VListItemAvatar',[_c('VIcon',[_vm._v("mdi-file-outline")])],1),(_vm.venue)?_c('VListItemContent',[_c('router-link',{staticClass:"mx-2",attrs:{"to":{
              name: 'legal.index',
              params: {
                type: _vm.type,
                id: _vm.venue,
              },
            },"target":"_blank"}},[_vm._v(_vm._s(_vm.label)+" URL ")])],1):_vm._e()],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }