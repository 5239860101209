export default [
  {
    text: 'venue.invoices.type',
    value: 'transactionType',
  },
  {
    text: 'venue.invoices.refOrNote',
    value: 'transactionNote',
    type: 'slot',
  },
  {
    text: 'venue.invoices.createdAt',
    value: 'createdAt',
    type: 'date',
    format: 'YYYY-MM-DD HH:mm',
  },
  {
    text: 'venue.invoices.amount',
    value: 'amount',
    type: 'decimal',
  },
  {
    text: 'venue.invoices.status',
    value: 'status',
    type: 'slot',
  },
];
