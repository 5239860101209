
import { Component, Prop, Model, Watch } from 'vue-property-decorator';
import { mixins } from 'vue-class-component';
import StackedForm from '@/mixins/StackedForm';
import { FeeByPostalCode } from '@/interfaces/models/delivery/FeeByPostalCode';
import DeliveryFeesForm from '@/components/venue/form/DeliveryFeesForm.vue';
import { Fee } from '@/interfaces/models/delivery/Fee';
import VFormBuilder from '@/components/shared/form/VFormBuilder.vue';

type FeesForm = InstanceType<typeof DeliveryFeesForm> & { fees: Fee[] };

@Component({
  components: {
    DeliveryFeesForm,
    VFormBuilder,
  },
})
export default class DeliveryFeesPostalCodeForm extends mixins(StackedForm) {
  @Prop({ type: Array, required: true, default: [] }) public postalCodes!: string[];
  @Model() public minValues!: { mov: string; fee: string };
  public fees: FeeByPostalCode[] = [];

  public $refs!: {
    feesForms: FeesForm[];
  };

  public mounted(): void {
    this.update();
  }

  get currency() {
    // @ts-ignore
    return this.initialValues ? this.initialValues.currency : 'EUR';
  }

  get selectedPostalCodes() {
    return this.fees.map((fee: FeeByPostalCode) => fee.postalCode);
  }

  get unselectedPostalCode() {
    return this.postalCodes.find((postalCode: string) => !this.selectedPostalCodes.includes(postalCode));
  }

  get unselectedPostalCodes() {
    return this.postalCodes.filter((postalCode: string) => !this.selectedPostalCodes.includes(postalCode));
  }

  get hasUnselectedPostalCode() {
    return this.unselectedPostalCode !== undefined;
  }

  public addFee() {
    const postalCode: string | undefined = this.unselectedPostalCode;

    if (postalCode) {
      this.fees.push({
        postalCode,
        mov: this.minValues.mov,
        fees: [
          {
            from: this.minValues.mov,
            fee: this.minValues.fee,
          },
        ],
      });
    }
  }

  public update() {
    // @ts-ignore
    if (this.initialValues && this.initialValues.movAndFee && this.initialValues.movAndFee.byPostalCodes) {
      this.fees = [];
      // @ts-ignore
      for (const fee of this.initialValues.movAndFee.byPostalCodes) {
        this.fees.push({
          postalCode: fee.postalCode,
          mov: (fee.mov && fee.mov.$numberDecimal) || fee.mov || this.minValues.mov,
          fees: fee.fees,
        });
      }
    }
  }

  public removeFee(fee: FeeByPostalCode) {
    const index: number = this.fees.indexOf(fee);

    if (index > -1) {
      this.fees.splice(index, 1);
    }
  }

  @Watch('initialValues')
  public onChange() {
    this.update();
  }

  public getData(): FeeByPostalCode[] {
    for (let i = 0; i < this.fees.length; ++i) {
      const feesForm: FeesForm | undefined =
        this.$refs.feesForms &&
        this.$refs.feesForms.find((form: FeesForm) => form.$attrs['data-key'].toString() === i.toString());
      this.fees[i].fees = (feesForm && feesForm.fees) || [];
    }

    return this.fees;
  }
}
