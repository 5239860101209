
import { Component } from 'vue-property-decorator';
import { mixins } from 'vue-class-component';
import { InputType } from '@/enums/InputType';
import VFormBuilder from '@/components/shared/form/VFormBuilder.vue';
import StackedForm from '@/mixins/StackedForm';
import { Venue } from '@/interfaces/models/Venue';
import _ from 'lodash';

@Component({
  components: { VFormBuilder },
})
export default class ExternalDeliveryServiceForm extends mixins(StackedForm) {
  // TODO - set correct type
  public $refs!: { externalDeliveryForm: any };

  public externalDeliveryService: any = [];

  get items() {
    return this.externalDeliveryService;
  }

  public mounted() {
    this.externalDeliveryService = _.cloneDeep(this.initValues ? this.initValues : []);
    if (this.externalDeliveryService && !this.externalDeliveryService.length) {
      this.addExternalDeliveryService();
    }
  }

  get initValues(): any {
    const venue: Venue = this.initialValues as Venue;

    if (venue && venue.externalDeliveryService) {
      return venue.externalDeliveryService;
    } else {
      return [];
    }
  }

  public addExternalDeliveryService() {
    this.externalDeliveryService.unshift({ name: '', link: '', icon: {} });
  }

  public removeExternalDeliveryService(index: number) {
    if (this.externalDeliveryService.length > index) {
      this.externalDeliveryService.splice(index, 1);
    }
  }

  get formItems() {
    return [
      {
        name: 'name',
        type: InputType.Text,
        label: 'venue.form.externalDeliveryService.name',
      },
      {
        name: 'link',
        type: InputType.Text,
        label: 'venue.form.externalDeliveryService.link',
      },
      {
        name: 'icon',
        type: InputType.File,
        default: null,
        accept: 'image/*',
      },
    ];
  }

  public getData(): any {
    const externalDeliveryService = this.$refs.externalDeliveryForm.reduce((acc: any, currentValue: any) => {
      acc.push({ ...currentValue.form });
      return acc;
    }, []);
    return { externalDeliveryService };
  }
}
