
import { Component, Vue } from 'vue-property-decorator';
import StackedForm from '@/mixins/StackedForm';
import { mixins } from 'vue-class-component';
import VOpeningHourBundle from '@/components/shared/domain/VOpeningHoursBundle.vue';
@Component({
  components: { VOpeningHourBundle },
})
export default class DeliveryHourForm extends mixins(StackedForm) {
  public validate() {
    // @ts-ignore
    return this.$refs.form.validate();
  }

  public getData() {
    // @ts-ignore
    return { deliveryHours: this.$refs.form.getHours() };
  }

  public mounted(): void {
    if (this.initialValues) {
      // @ts-ignore
      this.$refs.form.setHours(this.initialValues.deliveryHours);
    }
  }
}
