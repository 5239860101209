
import { Component, Prop, Vue } from 'vue-property-decorator';
import { ValidationObserver } from 'vee-validate';
import moment from 'moment';

interface OpeningHour {
  date: string | null;
  openedAt: string | null;
  closedAt: string | null;
  dateAtMenu: false;
  openedAtMenu: false;
  closedAtMenu: false;
  isClosed: boolean;
}

@Component
export default class VOpeningHour extends Vue {
  @Prop({ type: String, default: '' }) public title!: string;

  public $refs!: {
    observer: InstanceType<typeof ValidationObserver>;
  };
  public hours: OpeningHour[] = [];

  get weekdays() {
    return [
      { value: 0, text: this.$t('weekdays.monday') },
      { value: 1, text: this.$t('weekdays.tuesday') },
      { value: 2, text: this.$t('weekdays.wednesday') },
      { value: 3, text: this.$t('weekdays.thursday') },
      { value: 4, text: this.$t('weekdays.friday') },
      { value: 5, text: this.$t('weekdays.saturday') },
      { value: 6, text: this.$t('weekdays.sunday') },
    ];
  }

  public addOpeningHour() {
    this.hours.push({
      date: null,
      openedAt: null,
      openedAtMenu: false,
      dateAtMenu: false,
      closedAt: null,
      closedAtMenu: false,
      isClosed: false,
    });
  }

  public getHours() {
    return this.hours.map((h: OpeningHour) => {
      return {
        ...h,
        openedAt: this.getUtcTime(h.openedAt!),
        closedAt: this.getUtcTime(h.closedAt!),
      };
    });
  }

  public setHours(hours: OpeningHour[], overwrite: boolean = false): void {
    if (overwrite) {
      this.hours = [];
    }
    for (const hour of hours) {
      this.hours.push({
        date: moment(hour.date).isValid() ? moment(hour.date).format('YYYY-MM-DD') : '',
        openedAtMenu: false,
        closedAtMenu: false,
        dateAtMenu: false,
        isClosed: !!hour.isClosed,
        // @ts-ignore
        openedAt: this.toLocaleTime(hour.openedAt),
        // @ts-ignore
        closedAt: this.toLocaleTime(hour.closedAt),
      });
    }
  }

  public getUtcTime(time: string): string | null {
    if (!moment(time, 'HH:mm').isValid()) {
      return null;
    }

    if (moment().isDST()) {
      return moment(time, 'HH:mm').utc().format('HH:mm');
    }

    return moment(time, 'HH:mm').utc().add(-60, 'minutes').format('HH:mm');
  }

  public toLocaleTime(time: string): string | null {
    if (!moment(time, 'HH:mm').isValid()) {
      return null;
    }

    if (moment().isDST()) {
      return moment(time, 'HH:mm').add(moment().utcOffset(), 'minutes').format('HH:mm');
    }

    return moment(time, 'HH:mm')
      .add(moment().utcOffset() + 60, 'minutes')
      .format('HH:mm');
  }

  public removeHour(item: OpeningHour) {
    this.hours.splice(this.hours.indexOf(item), 1);
  }

  public validate(): Promise<boolean> {
    return this.$refs.observer.validate();
  }
}
