
import { Component, Prop, Watch } from 'vue-property-decorator';
import { Fee } from '@/interfaces/models/delivery/Fee';
import { mixins } from 'vue-class-component';
import StackedForm from '@/mixins/StackedForm';

@Component
export default class DeliveryFeesForm extends mixins(StackedForm) {
  @Prop({ type: String, required: true }) public currency!: string;

  public fees: Fee[] = [];

  public addFee(from?: string, fee?: string) {
    this.fees.push({
      from: from || this.movDefault,
      fee: fee || '0',
    });
  }

  public update() {
    // @ts-ignore
    if (this.initialValues && this.initialValues.fees) {
      this.fees = [];
      // @ts-ignore
      for (const fee of this.initialValues.fees) {
        this.fees.push({
          fee: (fee.fee && fee.fee.$numberDecimal) || fee.fee || this.movDefault,
          from: (fee.from && fee.from.$numberDecimal) || fee.from,
        });
      }
    }
  }

  public mounted(): void {
    this.update();
  }

  public removeFee(fee: Fee) {
    const index: number = this.fees.indexOf(fee);

    if (index > -1) {
      this.fees.splice(index, 1);
    }
  }

  get movDefault() {
    // @ts-ignore
    return (this.initialValues && this.initialValues.mov) || '0.00';
  }

  @Watch('initialValues')
  public onChange() {
    this.update();
  }
}
