
import { Component, Model, Watch } from 'vue-property-decorator';
import { mixins } from 'vue-class-component';
import StackedForm from '@/mixins/StackedForm';
import { FeeByRadius } from '@/interfaces/models/delivery/FeeByRadius';
import DeliveryFeesForm from '@/components/venue/form/DeliveryFeesForm.vue';
import { Fee } from '@/interfaces/models/delivery/Fee';
import VFormBuilder from '@/components/shared/form/VFormBuilder.vue';

type FeesForm = InstanceType<typeof DeliveryFeesForm> & { fees: Fee[] };

@Component({
  components: {
    DeliveryFeesForm,
    VFormBuilder,
  },
})
export default class DeliveryFeesRadiusForm extends mixins(StackedForm) {
  @Model() public minValues!: { mov: string; fee: string; deliveryRadius?: number };
  public fees: FeeByRadius[] = [];

  public $refs!: {
    feesForms: FeesForm[];
  };

  public mounted(): void {
    this.update();
  }

  public addFee() {
    this.fees.push({
      radius: this.maxRadius,
      mov: this.minValues.mov,
      fees: [
        {
          from: this.minValues.mov,
          fee: this.minValues.fee,
        },
      ],
    });
  }

  public update() {
    // @ts-ignore
    if (this.initialValues && this.initialValues.movAndFee && this.initialValues.movAndFee.byRadius) {
      this.fees = [];
      // @ts-ignore
      for (const fee of this.initialValues.movAndFee.byRadius) {
        this.fees.push({
          radius: fee.radius || this.maxRadius,
          mov: (fee.mov && fee.mov.$numberDecimal) || fee.mov || this.minValues.mov,
          fees: fee.fees,
        });
      }
    }
  }

  public removeFee(fee: FeeByRadius) {
    const index: number = this.fees.indexOf(fee);

    if (index > -1) {
      this.fees.splice(index, 1);
    }
  }

  get currency() {
    // @ts-ignore
    return this.initialValues ? this.initialValues.currency : 'EUR';
  }

  get maxRadius() {
    return this.minValues.deliveryRadius || 20;
  }

  @Watch('initialValues')
  public onChange() {
    this.update();
  }

  public getData(): FeeByRadius[] {
    for (let i = 0; i < this.fees.length; ++i) {
      const feesForm: FeesForm | undefined =
        this.$refs.feesForms &&
        this.$refs.feesForms.find((form: FeesForm) => form.$attrs['data-key'].toString() === i.toString());
      this.fees[i].fees = (feesForm && feesForm.fees) || [];
    }

    return this.fees;
  }
}
