
import { Component, Emit, Prop, Vue, Watch } from 'vue-property-decorator';
import { AxiosResponse } from 'axios';
import { SelectItem } from '@/interfaces/components/SelectItem';
import { HereApiService, HereGeocode, HereSuggestion } from '@/api/http/HereApiService';

@Component
export default class DeliveryPostalCode extends Vue {
  @Prop({ type: Array, default: () => [] }) public value!: string[];
  @Prop({ type: Boolean, default: false }) public disabled!: boolean;

  public search: string = '';
  public items: SelectItem[] = [];
  public selected: string[] = [];

  public mounted() {
    if (this.value) {
      this.init();
    }
  }

  @Watch('value')
  public onValueChange() {
    if (this.value && this.items.length === 0) {
      this.init();
    }
  }

  public init() {
    for (const val of this.value) {
      this.items.push({ text: val, value: val });
      this.selected.push(val);
    }
  }

  @Watch('selected')
  @Emit('input')
  @Emit('change')
  public onSelect(val: string[], oldVal: string[]) {
    if (val && oldVal && val.length > oldVal.length) {
      this.items = this.items.filter((i: SelectItem) => this.selected.indexOf(i.value as string) > -1);
      this.search = '';
    }
    return this.selected;
  }

  @Watch('search')
  public onSearch() {
    if (this.search && this.search.length >= 4) {
      this.items = this.items.filter((i: SelectItem) => this.selected.indexOf(i.value as string) > -1);
      this.$startLoading('venue.deliveryPostalCodes');
      HereApiService.partialLookup({ postalCode: this.search })
        .then((res: AxiosResponse<{ items: HereGeocode[] }>) => {
          if (res.data.items) {
            for (const result of res.data.items) {
              if (result.address.postalCode && result.address.postalCode.length > 1) {
                this.items.push({
                  text: `${result.address.postalCode} ${result.address.city ? result.address.city : ''} `,
                  value: result.address.postalCode,
                });
              }
            }
          }
        })
        .finally(() => {
          this.$stopLoading('venue.deliveryPostalCodes');
        });
    }
  }
}
