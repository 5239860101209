
import { Component, Emit } from 'vue-property-decorator';
import VFormBuilder from '../../shared/form/VFormBuilder.vue';
import { InputType } from '@/enums/InputType';
import timezones from '@/util/timezones';
import StackedForm from '@/mixins/StackedForm';
import { mixins } from 'vue-class-component';
import { Localization } from '@/interfaces/models/Localization';
import CustomerGroup from '@/mixins/CustomerGroup';
import { HereApiService, Address, Location } from '@/api/http/HereApiService';
import Countries from '@/mixins/Countries';
import LegalForm from '@/mixins/LegalForm';
import { Venue } from '@/interfaces/models/Venue';
import { Country } from '@/enums/Country';
import { generateQRCode, getListOfEmailsRegexp, toDownloadImage } from '@/util/helper';
import cookie from 'js-cookie';
import { SubwayRoleType } from '@/enums/SubwayRoleType';
import { Permission } from '@/enums/Permission';

interface EodFormModel {
  sendEodReport: boolean;
  sendEodReportEmails: string;
}

@Component({
  components: { VFormBuilder },
})
export default class GeneralForm extends mixins(StackedForm, CustomerGroup, Countries, LegalForm) {
  public country: Country | null = null;
  public useVatNumberGermany: boolean = false;
  public useManualGpsInput: boolean = false;

  public qrCodeOfStore: string = '';

  public $refs!: {
    baseForm: InstanceType<typeof VFormBuilder> & { form: any };
    eodForm: InstanceType<typeof VFormBuilder> & { form: EodFormModel };
    contactForm: InstanceType<typeof VFormBuilder> & { form: any };
    invoiceReceiverForm: InstanceType<typeof VFormBuilder> & { form: any };
    customerSupportMail: InstanceType<typeof VFormBuilder> & { form: any };
    parkCollectForm: InstanceType<typeof VFormBuilder> & { form: any };
    addressForm: InstanceType<typeof VFormBuilder> & { form: any };
    miscellaneousForm: InstanceType<typeof VFormBuilder> & { form: any };
    pickupBoxCredentialsForm: InstanceType<typeof VFormBuilder> & { form: any };
    // sdCredentialsForm:  InstanceType<typeof VFormBuilder> & { form: any };
  };

  public async mounted() {
    if (this.initialValues) {
      // @ts-ignore
      if (!this.initialValues.vatNumber && this.initialValues.vatNumberGermany) {
        this.useVatNumberGermany = true;
      }
      // @ts-ignore
      if (this.initialValues.country) {
        // @ts-ignore
        this.country = this.initialValues.country;
      }
      // @ts-ignore
      if (this.initialValues?.readableId && this.isSubwayRole) {
        // @ts-ignore
        this.qrCodeOfStore = await generateQRCode(this.subwayStoreUrl);
      }
    }
  }

  public async save() {
    // @ts-ignore
    this.$refs.eodForm.validate();
  }

  get readableIdRules() {
    if (this.$route.params.id) {
      return `readable_id:${this.$route.params.id}`;
    }
    return `readable_id`;
  }

  @Emit('change:base')
  public onBasicDataChange(val: string, key: string) {
    const invoiceReceiver: Partial<Venue> =
      // @ts-ignore
      this.initialValues && this.initialValues.invoiceReceiver ? { ...this.initialValues.invoiceReceiver } : {};
    const basicData: Partial<Venue> = { ...this.initialValues, invoiceReceiver } as Partial<Venue>;
    if (!key.includes('invoiceReceiver')) {
      // @ts-ignore
      basicData[key] = val;
    } else {
      const parts: string[] = key.split('.');
      // @ts-ignore
      basicData.invoiceReceiver[parts[1]] = val;
    }

    return basicData;
  }

  @Emit('notifyError')
  public notifyError(msg: string): string {
    return msg;
  }

  get initialAddressValues() {
    const initialValues: object = { ...this.initialValues };

    if (
      // @ts-ignore
      initialValues.location &&
      // @ts-ignore
      initialValues.location.coordinates &&
      // @ts-ignore
      initialValues.location.coordinates.length > 1
    ) {
      // @ts-ignore
      initialValues.longitude = this.initialValues.location.coordinates[0];
      // @ts-ignore
      initialValues.latitude = this.initialValues.location.coordinates[1];
    }

    return initialValues;
  }

  get initialParkCollectValues() {
    const initialValues: object = { ...this.initialValues };
    // @ts-ignore
    if (
      // @ts-ignore
      initialValues.parkCollectLocation
    ) {
      // @ts-ignore
      initialValues.lng = this.initialValues.parkCollectLocation.lng;
      // @ts-ignore
      initialValues.lat = this.initialValues.parkCollectLocation.lat;
    }

    return initialValues;
  }

  get baseItems() {
    return [
      { name: 'assets', type: InputType.File, label: 'venue.form.image', default: null, accept: 'image/*' },
      { name: 'name', type: InputType.Text, label: 'venue.form.name', rules: 'required' },
      { name: '_id', type: InputType.Text, label: 'venue.form.restaurantToken', readonly: true },
      { name: 'readableId', type: InputType.Text, label: 'venue.form.readableId', rules: this.readableIdRules },
      {
        name: 'webAppSlug',
        type: InputType.Text,
        label: 'venue.form.webAppSlug',
        readonly: !this.$can(Permission.VENUE_SLUG_GENERATE),
      },
      { name: 'masterId', type: InputType.Text, label: 'venue.form.masterId' },
      { name: 'sapNumber', type: InputType.Text, label: 'venue.form.sapNumber' },
      { name: 'externalLocationId', type: InputType.Text, label: 'venue.form.externalLocationId' },
      { name: 'isPublished', type: InputType.Checkbox, label: 'venue.form.isPublished' },
      { name: 'isServiceActivated', type: InputType.Checkbox, label: 'venue.form.isServiceActivated' },
      { name: 'smoothrDispatchEnabled', type: InputType.Checkbox, label: 'venue.form.smoothrDispatchEnabled' },
      { name: 'sapCCOEnabled', type: InputType.Checkbox, label: 'venue.form.sapCCOEnabled' },
      { name: 'isCallingSystemEnabled', type: InputType.Checkbox, label: 'venue.form.isCallingSystemEnabled' },
      { name: 'inventoryEnabled', type: InputType.Checkbox, label: 'venue.form.inventoryEnabled' },
      {
        name: 'locType',
        type: InputType.Select,
        label: 'venue.form.locType',
        default: 'restaurant',
        items: this.locTypes,
      },
      {
        name: 'legalForm',
        type: InputType.Select,
        label: 'venue.form.legalForm',
        rules: 'required',
        items: this.legalForms,
      },
      {
        name: 'timezone',
        type: InputType.Select,
        label: 'venue.form.timezone',
        default: 'Europe/Berlin',
        items: this.timezones,
      },
      {
        name: 'customerGroup',
        type: InputType.Select,
        label: 'venue.form.customerGroup',
        items: this.customerGroups,
      },
    ];
  }

  get eodItems() {
    return [
      {
        name: 'sendEodReport',
        type: InputType.Checkbox,
        label: 'venue.form.sendEodReport',
        default: false,
        rules: this.getEodEmailRules,
      },
      {
        name: 'sendEodReportEmails',
        type: InputType.Text,
        label: 'venue.form.sendEodReportEmails',
        rules: 'list_of_emails',
      },
    ];
  }

  get contactItems() {
    return [
      {
        name: 'email',
        type: InputType.Text,
        label: 'venue.form.email',
        rules: 'required|email',
        change: (val: string) => this.onBasicDataChange(val, 'email'),
      },
      { name: 'orderEmail', type: InputType.Text, label: 'venue.form.orderEmail', rules: 'email' },
      { name: 'website', type: InputType.Text, label: 'venue.form.web' },
      { name: 'phoneNumber', type: InputType.Text, label: 'venue.form.phone' },
    ];
  }

  get miscellaneousItems() {
    return [
      {
        name: 'sendProvisionReportPeriod',
        type: InputType.Select,
        default: 'never',
        label: 'venue.form.sendProvisionReportPeriod',
        items: this.periods,
      },
      { name: 'lastInvoiceNumber', type: InputType.Text, default: 1, label: 'venue.form.lastInvoiceNumber' },
      {
        name: 'sendProvisionReportPeriodNonInvoiceable',
        default: 'never',
        type: InputType.Select,
        label: 'venue.form.sendProvisionReportPeriodNonInvoiceable',
        items: this.periods,
      },
      {
        name: 'vatNumber',
        type: InputType.Text,
        label: 'venue.form.vatNumber',
        rules: 'required|alpha_num|uppercase|min:5|max:20|vat_number:venue',
        change: (val: string) => this.onBasicDataChange(val, 'vatNumber'),
        visible: !this.useVatNumberGermany,
      },
      {
        name: 'vatNumberGermany',
        type: InputType.Text,
        label: 'venue.form.vatNumberGermany',
        rules: 'required|min:5|max:20|vat_number_germany:venue',
        change: (val: string) => this.onBasicDataChange(val, 'vatNumberGermany'),
        visible: this.useVatNumberGermany,
      },
      {
        name: 'secureCode',
        type: InputType.Text,
        default: '9500',
        label: 'venue.form.secureCode',
        rules: 'required',
      },
      {
        name: 'selfCertificationAsFile',
        type: InputType.Radio,
        default: true,
        label: 'venue.form.selfCertification',
        items: this.additionalOptions,
      },
      {
        name: 'nutritionTableAsFile',
        type: InputType.Radio,
        default: true,
        label: 'venue.form.secureCode',
        items: this.additionalOptions,
      },
      {
        name: 'reviewHyperlink',
        type: InputType.Text,
        clearable: true,
        label: 'venue.form.reviewHyperlink',
      },
    ];
  }

  get invoiceReceiverItems() {
    return [
      {
        name: 'email',
        type: InputType.Text,
        label: 'venue.form.invoiceReceiver.email',
        rules: 'email',
        change: (val: string) => this.onBasicDataChange(val, 'invoiceReceiver.email'),
      },
      {
        name: 'fullName',
        type: InputType.Text,
        label: 'venue.form.fullName',
        rules: 'required',
        change: (val: string) => this.onBasicDataChange(val, 'invoiceReceiver.fullName'),
      },
      {
        name: 'street',
        type: InputType.Text,
        label: 'venue.form.street',
        rules: 'required',
        change: (val: string) => this.onBasicDataChange(val, 'invoiceReceiver.street'),
      },
      {
        name: 'number',
        type: InputType.Text,
        label: 'venue.form.number',
        rules: 'required',
        change: (val: string) => this.onBasicDataChange(val, 'invoiceReceiver.number'),
      },
      {
        name: 'postalCode',
        type: InputType.Text,
        label: 'venue.form.postalCode',
        rules: 'required',
        change: (val: string) => this.onBasicDataChange(val, 'invoiceReceiver.postalCode'),
      },
      {
        name: 'city',
        type: InputType.Text,
        label: 'venue.form.city',
        rules: 'required',
        change: (val: string) => this.onBasicDataChange(val, 'invoiceReceiver.city'),
      },
    ];
  }

  get customerSupportMail() {
    return [
      {
        name: 'customerSupportMail',
        type: InputType.Text,
        label: 'venue.form.customerSupportMail.email',
        rules: 'email',
      },
    ];
  }

  get addressItems() {
    return [
      {
        name: 'country',
        type: InputType.Select,
        label: 'venue.form.country',
        rules: 'required',
        items: this.countries,
        change: this.changedCountry,
      },
      {
        name: 'street',
        type: InputType.Text,
        label: 'venue.form.street',
        rules: 'required',
        change: this.changedAddress,
      },
      {
        name: 'number',
        type: InputType.Text,
        label: 'venue.form.number',
        rules: 'required',
        change: this.changedAddress,
      },
      {
        name: 'city',
        type: InputType.Language,
        label: 'venue.form.city',
        rules: 'required',
        change: this.changedAddress,
      },
      { name: 'area', type: InputType.Text, label: 'venue.form.area', rules: 'required' },
      {
        name: 'postalCode',
        type: InputType.Text,
        label: 'venue.form.postalCode',
        rules: 'required',
        change: this.changedAddress,
      },
      {
        name: 'federalState',
        type: InputType.Select,
        label: 'venue.form.federalState',
        items: this.federalStatesGermany,
        visible: this.showFederalStateField,
      },
      {
        name: 'googlePlacesId',
        type: InputType.Text,
        label: 'venue.form.googlePlacesId',
      },
      {
        name: 'latitude',
        type: InputType.Text,
        label: 'venue.form.latitude',
        disabled: !this.useManualGpsInput,
      },
      {
        name: 'longitude',
        type: InputType.Text,
        label: 'venue.form.longitude',
        disabled: !this.useManualGpsInput,
      },
    ];
  }

  get parkCollectForm() {
    return [
      {
        name: 'lat',
        type: InputType.Text,
        label: 'venue.form.latitude',
      },
      {
        name: 'lng',
        type: InputType.Text,
        label: 'venue.form.longitude',
      },
      {
        name: 'parkCollectionLocationText',
        type: InputType.Language,
        label: 'venue.form.parkCollectionLocationText',
      },
    ];
  }

  get pickupBoxCredentialsItems() {
    return [
      {
        name: 'pickupBoxKey',
        type: InputType.Text,
        label: 'venue.form.pickupBoxKey',
      },
      {
        name: 'pickupBoxDeviceId',
        type: InputType.Text,
        label: 'venue.form.pickupBoxDeviceId',
      },
      {
        name: 'pickupBoxAppId',
        type: InputType.Text,
        label: 'venue.form.pickupBoxAppId',
      },
    ];
  }

  get sdCredentialsItems() {
    return [
      {
        name: 'id',
        type: InputType.Text,
        default: null,
        label: 'venue.form.id',
      },
      {
        name: 'apiGateway',
        type: InputType.Text,
        default: null,
        label: 'venue.form.apiGateway',
      },
      {
        name: 'apiKey',
        type: InputType.Text,
        default: null,
        label: 'venue.form.apiKey',
      },
      {
        name: 'host',
        type: InputType.Text,
        default: null,
        label: 'venue.form.host',
      },
    ];
  }

  get eodOptions() {
    return [{ text: this.$t('venue.form.sendEod'), value: false }];
  }

  get additionalOptions() {
    return [
      { text: this.$t('venue.form.pdfUpload'), value: true },
      { text: this.$t('venue.form.insertLink'), value: false },
    ];
  }

  get locTypes() {
    return [
      { text: this.$t('venue.locTypes.bar'), value: 'bar' },
      { text: this.$t('venue.locTypes.club'), value: 'club' },
      { text: this.$t('venue.locTypes.restaurant'), value: 'restaurant' },
      { text: this.$t('venue.locTypes.db'), value: 'db' },
      { text: this.$t('venue.locTypes.cinema'), value: 'cinema' },
      { text: this.$t('venue.locTypes.other'), value: 'other' },
    ];
  }

  get federalStatesGermany() {
    return [
      { text: 'Baden-Württemberg', value: 'Baden-Württemberg' },
      { text: 'Bayern', value: 'Bayern' },
      { text: 'Berlin', value: 'Berlin' },
      { text: 'Brandenburg', value: 'Brandenburg' },
      { text: 'Bremen', value: 'Bremen' },
      { text: 'Hamburg', value: 'Hamburg' },
      { text: 'Hessen', value: 'Hessen' },
      { text: 'Mecklenburg-Vorpommern', value: 'Mecklenburg-Vorpommern' },
      { text: 'Niedersachsen', value: 'Niedersachsen' },
      { text: 'Nordrhein-Westfalen', value: 'Nordrhein-Westfalen' },
      { text: 'Rheinland-Pfalz', value: 'Rheinland-Pfalz' },
      { text: 'Saarland', value: 'Saarland' },
      { text: 'Sachsen', value: 'Sachsen' },
      { text: 'Sachsen-Anhalt', value: 'Sachsen-Anhalt' },
      { text: 'Schleswig-Holstein', value: 'Schleswig-Holstein' },
      { text: 'Thüringen', value: 'Thüringen' },
    ];
  }

  get periods() {
    return [
      { text: this.$t('periods.never'), value: 'never' },
      { text: this.$t('periods.daily'), value: 'daily' },
      { text: this.$t('periods.weekly'), value: 'weekly' },
      { text: this.$t('periods.monthly'), value: 'monthly' },
    ];
  }

  get timezones() {
    return timezones;
  }

  get showVatNumberGermanyOption() {
    // @ts-ignore
    return this.country && this.country === Country.DE;
  }

  get showFederalStateField() {
    // @ts-ignore
    return this.country && this.country === Country.DE;
  }

  public async changedCountry(country: Country) {
    this.country = country;
    if (this.useVatNumberGermany && this.country !== Country.DE) {
      this.useVatNumberGermany = false;
    }
  }

  public get isSubwayRole() {
    return Object.values(SubwayRoleType).includes(cookie.get('role') as SubwayRoleType);
  }

  public get subwayStoreUrl() {
    // @ts-ignore
    return `${process.env.VUE_APP_SUBWAY_URL}${this.initialValues.readableId}`;
  }

  public downloadQRCodeOfStore() {
    toDownloadImage(this.qrCodeOfStore, 'qrcode.png');
  }

  public async changedAddress(
    _: any,
    form: {
      street: string;
      number: string;
      postalCode: string;
      city: Localization;
      longitude: number;
      latitude: number;
      location?: { coordinates: number[]; type: string };
    },
  ) {
    if (form.street !== '' && form.number !== '' && form.postalCode !== '' && form.city.de !== '') {
      this.$startLoading('venue.location');
      const location: Location | null = await HereApiService.getGeoCoordinates(form as unknown as Address);
      if (location) {
        if (!form.location) {
          form.location = { coordinates: [0, 0], type: 'Point' };
        }
        form.location.type = 'Point';
        form.location.coordinates[0] = location.lng;
        form.location.coordinates[1] = location.lat;
        this.$refs.addressForm.setData({ ...form, latitude: location.lat, longitude: location.lng });
      } else {
        this.notifyError('notification.gpsError');
      }
      this.$stopLoading('venue.location');
    }
  }

  public toggleVatNumberGermany() {
    this.useVatNumberGermany = !this.useVatNumberGermany;
  }

  public getBaseFormData() {
    const baseFormData: any = { ...this.$refs.baseForm.form };

    if (baseFormData.hasOwnProperty('_id')) {
      delete baseFormData._id;
    }

    if (!this.$can(Permission.VENUE_SLUG_GENERATE)) {
      delete baseFormData.webAppSlug;
    }

    return baseFormData;
  }

  public generateSlug(form: any) {
    let result = form.name.toLowerCase();
    result = result
      .replace(/ä/g, 'ae')
      .replace(/ö/g, 'oe')
      .replace(/ü/g, 'ue')
      .replace(/ß/g, 'ss')
      .replace(/\s+/g, '')
      .replace(/&/g, ''); // Remove & character
    this.$refs.baseForm.setValue('webAppSlug', result);
    this.$forceUpdate();
  }

  public getAddressData() {
    const addressData: any = { ...this.$refs.addressForm.form };

    if (!addressData.location) {
      addressData.location = { coordinates: [0, 0], type: 'Point' };
    }

    addressData.location.coordinates[0] = parseFloat(addressData.longitude) || 0.0;
    addressData.location.coordinates[1] = parseFloat(addressData.latitude) || 0.0;

    delete addressData.longitude;
    delete addressData.latitude;

    return addressData;
  }

  public getParkCollectData() {
    const parkCollectData: any = { ...this.$refs.parkCollectForm.form };

    return {
      parkCollectLocation: {
        lat: parkCollectData.lat,
        lng: parkCollectData.lng,
      },
      parkCollectionLocationText: parkCollectData.parkCollectionLocationText,
    };
  }

  public getEodEmailRules(form: EodFormModel) {
    const canBeSaved = getListOfEmailsRegexp().test(form.sendEodReportEmails);

    if (!canBeSaved) {
      return 'eod_email_enable';
    }
  }

  fileRemoved() {
    this.$refs.baseForm.form.assets = { removed: true };
  }

  public getData() {
    if (this.useVatNumberGermany) {
      this.$refs.miscellaneousForm.form.vatNumber = '';
    } else {
      this.$refs.miscellaneousForm.form.vatNumberGermany = null;
    }

    return {
      ...this.getBaseFormData(),
      ...this.$refs.eodForm.form,
      ...this.$refs.contactForm.form,
      ...this.getAddressData(),
      ...this.getParkCollectData(),
      ...this.$refs.miscellaneousForm.form,
      ...this.$refs.customerSupportMail.form,
      invoiceReceiver: {
        ...this.$refs.invoiceReceiverForm.form,
      },
      pickupBoxCredentials: {
        ...this.$refs.pickupBoxCredentialsForm.getData(),
      },
    };
  }
}
