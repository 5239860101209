
import { Component, Prop } from 'vue-property-decorator';
import { InputType } from '@/enums/InputType';
import VFormBuilder from '@/components/shared/form/VFormBuilder.vue';
import StackedForm from '@/mixins/StackedForm';
import { mixins } from 'vue-class-component';
import { Venue } from '@/interfaces/models/Venue';
import { Delfi } from '@/interfaces/models/Delfi';
import DelfiLoader from '../DelfiLoader.vue';
import { DelfiApiService } from '@/api/http/DelfiApiService';
import { AxiosResponse } from 'axios';

@Component({
  components: { VFormBuilder, DelfiLoader },
})
export default class DelfiForm extends mixins(StackedForm) {
  @Prop({ type: Object, required: true }) public venue!: Venue;

  public delfi: Delfi | null = null;
  public formActivationVisible: boolean = false;

  public $refs!: {
    form: InstanceType<typeof VFormBuilder> & { form: any };
  };

  public async initData() {
    if (!this.venue) return;
    try {
      this.$startLoading('venue.delfi');
      const api: DelfiApiService = new DelfiApiService();
      const res: AxiosResponse<Delfi> = await api.getDelfi(this.venue._id);
      if (res && res.data) {
        this.delfi = res.data;
      }
    } finally {
      if (this.delfi?.venueId === this.venue?._id) {
        this.formActivationVisible = true;
      }
      this.$stopLoading('venue.delfi');
    }
  }

  get items() {
    return [
      {
        name: 'installationId',
        type: InputType.Text,
        label: 'venue.form.installationId',
        rules: 'required',
      },
      {
        name: 'locationId',
        type: InputType.Text,
        label: 'venue.form.locationId',
        rules: 'required',
      },
      {
        name: 'authenticationToken',
        type: InputType.Text,
        label: 'venue.form.authenticationToken',
        rules: 'required',
      },
      {
        name: 'defaultTemplate',
        type: InputType.Text,
        label: 'venue.form.defaultTemplate',
        default: 'STANDARD',
        rules: 'required',
      },
      {
        name: 'enabled',
        type: InputType.Checkbox,
        label: 'venue.form.enabled',
        rules: 'required',
      },
      {
        name: 'syncMenu',
        type: InputType.Checkbox,
        label: 'venue.form.syncMenu',
        rules: 'required',
      },
    ];
  }

  get initial() {
    return this.initialValues as Venue;
  }

  public isDelfi() {
    return !!this.delfi;
  }

  public getDelfi() {
    if (!this.$refs.form) {
      return;
    }
    return {
      venueId: this.venue._id,
      ...this.$refs.form.form,
    };
  }
}
