
import { Component, Vue } from 'vue-property-decorator';
import { InputType } from '@/enums/InputType';
import VFormBuilder from '@/components/shared/form/VFormBuilder.vue';
import StackedForm from '@/mixins/StackedForm';
import { mixins } from 'vue-class-component';

@Component({
  components: { VFormBuilder },
})
export default class TerminalForm extends mixins(StackedForm) {
  public $refs!: {
    sizeForm: InstanceType<typeof VFormBuilder> & { form: any };
    settingsForm: InstanceType<typeof VFormBuilder> & { form: any };
  };

  get spanItems() {
    return [
      { name: 'articleSpanCount', type: InputType.Text, label: 'venue.form.articleSpanCount', default: 2 },
      { name: 'categorySpanCount', type: InputType.Text, label: 'venue.form.categorySpanCount', default: 2 },
      { name: 'fullWidthCategories', type: InputType.Text, label: 'venue.form.fullWidthCategories', default: 2 },
    ];
  }

  get settingItems() {
    return [
      {
        name: 'showInactiveArticles',
        type: InputType.Checkbox,
        label: 'venue.form.showInactiveArticles',
        default: true,
      },
      { name: 'askForTableNumber', type: InputType.Checkbox, label: 'venue.form.askForTableNumber', default: false },
      {
        name: 'foodAndBeveragesSeparated',
        type: InputType.Checkbox,
        label: 'venue.form.foodAndBeveragesSeparated',
        default: false,
      },
      { name: 'toolbarTitleHidden', type: InputType.Checkbox, label: 'venue.form.toolbarTitleHidden', default: true },
      {
        name: 'displayCirclePickerDescription',
        type: InputType.Checkbox,
        label: 'venue.form.displayCirclePickerDescription',
        default: true,
      },
      {
        name: 'toolbarCategoryListVisible',
        type: InputType.Checkbox,
        label: 'venue.form.toolbarCategoryListVisible',
        default: true,
      },
      {
        name: 'toolbarSidesVisible',
        type: InputType.Checkbox,
        label: 'venue.form.toolbarSidesVisible',
        default: false,
      },
      { name: 'multiLanguage', type: InputType.Checkbox, label: 'venue.form.multiLanguage', default: false },
      { name: 'printQr', type: InputType.Checkbox, label: 'venue.form.printQr', default: false },
    ];
  }

  public getData() {
    return {
      terminalConfig: {
        ...this.$refs.settingsForm.form,
        ...this.$refs.sizeForm.form,
      },
    };
  }
}
