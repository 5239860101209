
import { Component } from 'vue-property-decorator';
import VOpeningHourBundle from '@/components/shared/domain/VOpeningHoursBundle.vue';
import StackedForm from '@/mixins/StackedForm';
import { mixins } from 'vue-class-component';
import { OpeningHour } from '@/interfaces/models/OpeningHour';
import VSpecialOpeningHour from '@/components/shared/domain/VSpecialOpeningHour.vue';

@Component({
  components: { VOpeningHourBundle, VSpecialOpeningHour },
})
export default class OpeningHourForm extends mixins(StackedForm) {
  public $refs!: {
    specialOpeningHour: InstanceType<typeof VSpecialOpeningHour> & {
      hours: OpeningHour[];
      getHours: () => OpeningHour[];
      setHours: (hours: any[], overwrite?: boolean) => void;
    };
    openingHour: InstanceType<typeof VOpeningHourBundle> & {
      validate: () => Promise<boolean>;
      hours: OpeningHour[];
      getHours: () => OpeningHour[];
      setHours: (hours: OpeningHour[], overwrite?: boolean) => void;
    };
  };

  public async validate(): Promise<boolean> {
    return this.$refs.openingHour?.validate();
  }

  public getData() {
    return {
      openingHours: this.$refs.openingHour.getHours(),
      specialOpeningHours: this.$refs.specialOpeningHour?.getHours() ?? null,
    };
  }

  public setData(data: OpeningHour[]) {
    this.$refs.openingHour.setHours(data, true);
  }

  public mounted(): void {
    if (this.initialValues) {
      // @ts-ignore
      this.$refs.specialOpeningHour.setHours(this.initialValues.specialOpeningHours);
      // @ts-ignore
      this.$refs.openingHour.setHours(this.initialValues.openingHours);
    }
  }
}
