
import { Component } from 'vue-property-decorator';
import { InputType } from '@/enums/InputType';
import VFormBuilder from '@/components/shared/form/VFormBuilder.vue';
import StackedForm from '@/mixins/StackedForm';
import { mixins } from 'vue-class-component';

@Component({
  components: { VFormBuilder },
})
export default class SalesforceForm extends mixins(StackedForm) {
  public $refs!: {
    form: InstanceType<typeof VFormBuilder> & { form: any };
  };

  get items() {
    return [
      { name: 'sandboxUrl', type: InputType.Text, label: 'venue.form.sandboxUrl' },
      { name: 'clientId', type: InputType.Text, label: 'venue.form.clientId' },
      { name: 'clientSecret', type: InputType.Text, label: 'venue.form.clientSecret' },
      { name: 'username', type: InputType.Text, label: 'venue.form.username' },
      { name: 'password', type: InputType.Password, label: 'venue.form.password' },
      { name: 'recordType', type: InputType.Text, label: 'venue.form.recordType' },
      { name: 'channelPc', type: InputType.Text, label: 'venue.form.channelPc' },
      { name: 'enabled', type: InputType.Checkbox, label: 'venue.form.enabled' },
    ];
  }

  public getData() {
    return {
      salesforce: { ...this.$refs.form.form },
    };
  }
}
