import { Component, Prop, Vue } from 'vue-property-decorator';
import { Route } from 'vue-router';

@Component
export default class Editable extends Vue {
  get id(): string {
    return this.$route.params.id;
  }

  public $route!: Route;
  @Prop({ type: Boolean, default: false }) protected readonly editing!: boolean;
}
