import { Component, Vue } from 'vue-property-decorator';
import { Country } from '@/enums/Country';

@Component
export default class Countries extends Vue {
  get countries() {
    return [
      { text: this.$t('countries.de'), value: Country.DE },
      { text: this.$t('countries.au'), value: Country.AU },
      { text: this.$t('countries.fr'), value: Country.FR },
      { text: this.$t('countries.lu'), value: Country.LU },
      { text: this.$t('countries.ch'), value: Country.CH },
      { text: this.$t('countries.es'), value: Country.ES },
      { text: this.$t('countries.is'), value: Country.IS },
      { text: this.$t('countries.ic'), value: Country.IC },
      { text: this.$t('countries.pt'), value: Country.PT },
      /*{ text: this.$t('countries.ch'), value: 'ch' },
			{ text: this.$t('countries.lb'), value: 'lb' },
			{ text: this.$t('countries.li'), value: 'li' }, */
    ];
  }
}
