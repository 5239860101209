
import { Component, Emit, Prop } from 'vue-property-decorator';
import VFormBuilder from '@/components/shared/form/VFormBuilder.vue';
import StackedForm from '@/mixins/StackedForm';
import { mixins } from 'vue-class-component';
import { VenueInvoiceType } from '@/enums/VenueInvoiceType';
import { Venue } from '@/interfaces/models/Venue';
import { VenueTransaction, VenueTransactions } from '@/interfaces/models/VenueTransactions';
import headers from './InvoiceFormHeaders';
import { ColumnHeader } from '@/interfaces/components/ColumnHeader';
import VTable from '@/components/shared/table/VTable.vue';
import { InputType } from '@/enums/InputType';
import VConfirm from '@/components/shared/VConfirm.vue';
import { VenueTransactionStatus } from '@/enums/VenueTransactionStatus';

@Component({
  components: { VConfirm, VFormBuilder, VTable },
})
export default class InvoiceForm extends mixins(StackedForm) {
  get invoiceTypeItems(): Array<{ text: string; value: VenueInvoiceType }> {
    const types: Array<{ text: string; value: VenueInvoiceType }> = [];

    types.push({ text: `${this.$t('venue.invoices.setupFee99')}`, value: VenueInvoiceType.SETUP_FEE_99 });
    types.push({ text: `${this.$t('venue.invoices.setupFee49')}`, value: VenueInvoiceType.SETUP_FEE_49 });
    types.push({ text: `${this.$t('venue.invoices.printerTablet')}`, value: VenueInvoiceType.PRINTER_TABLET });
    types.push({
      text: `${this.$t('venue.invoices.premiumPrinterPackage')}`,
      value: VenueInvoiceType.PRINTER_PREMIUM,
    });
    types.push({
      text: `${this.$t('venue.invoices.printerTablet200')}`,
      value: VenueInvoiceType.PRINTER_TABLET_200,
    });
    types.push({
      text: `${this.$t('venue.invoices.printerTablet230')}`,
      value: VenueInvoiceType.PRINTER_TABLET_230,
    });

    return types;
  }

  get creditNoteItems() {
    return [
      { name: 'note', type: InputType.Text, label: 'common.description' },
      { name: 'amount', type: InputType.Price, label: 'venue.invoices.amount', default: '0.00' },
    ];
  }

  get transactionItems() {
    const transactions: VenueTransaction[] = (this.venueTransactions && this.venueTransactions.transactions) || [];

    return transactions.map((transaction: VenueTransaction) => {
      return {
        ...transaction,
        amount: parseFloat(transaction.amount),
        transactionType: this.transactionTableType(transaction),
        transactionNote: this.transactionNote(transaction),
      };
    });
  }

  get balance() {
    return (this.venueTransactions && parseFloat(this.venueTransactions.balance)) || 0;
  }
  @Prop({ type: Object, required: true }) public venue!: Venue;
  @Prop({ type: Object, required: false }) public venueTransactions!: VenueTransactions;

  public selectedInvoiceType: VenueInvoiceType = this.invoiceTypeItems[0].value;
  public tableHeaders: ColumnHeader[] = headers;
  public VenueInvoiceType = VenueInvoiceType;
  public VenueTransactionStatus = VenueTransactionStatus;

  public $refs!: {
    form: InstanceType<typeof VFormBuilder> & { form: any };
    creditNoteForm: InstanceType<typeof VFormBuilder> & {
      getData: () => any;
      validate: () => Promise<boolean | boolean[]>;
    };
    confirmPay: InstanceType<typeof VConfirm> & {
      setText: (str: string) => void;
      open: (result: {} | PromiseLike<{}> | undefined) => Promise<boolean>;
    };
  };

  @Emit('load')
  public async mounted(): Promise<void> {
    if (this.$refs.confirmPay) {
      this.$refs.confirmPay.setText(`${this.$t('venue.invoices.sepa.payWarning')}`);
    }
  }

  @Emit('sendInvoice')
  public sendInvoice(): VenueInvoiceType {
    return this.selectedInvoiceType;
  }

  @Emit('createCreditNote')
  public createCreditNote(): void {
    return this.$refs.creditNoteForm.getData();
  }

  public downloadInvoicePdf(transaction: VenueTransaction): void {
    this.$emit('downloadInvoicePdf', transaction);
  }

  public downloadOrdersPdf(transaction: VenueTransaction): void {
    this.$emit('downloadOrdersPdf', transaction);
  }

  public paySepaDialog(transaction: VenueTransaction): void {
    this.$refs.confirmPay.open(async (result: {} | PromiseLike<{}> | undefined) => {
      if (await result) {
        const description: string = `Smoothr-Invoice ${transaction.invoiceRef}`;
        this.$emit('sepaPay', transaction, description);
      }
    });
  }

  protected transactionTableType(transaction: VenueTransaction): string {
    let transactionType: string = '';
    switch (transaction.invoiceType) {
      case VenueInvoiceType.SETUP_FEE_99:
        transactionType = this.$t('venue.invoices.setupFee99').toString();
        break;
      case VenueInvoiceType.SETUP_FEE_49:
        transactionType = this.$t('venue.invoices.setupFee49').toString();
        break;
      case VenueInvoiceType.PRINTER_PREMIUM:
        transactionType = this.$t('venue.invoices.premiumPrinterPackage').toString();
        break;
      case VenueInvoiceType.PRINTER_TABLET:
        transactionType = this.$t('venue.invoices.printerTablet').toString();
        break;
      case VenueInvoiceType.PROVISION_INVOICE:
        transactionType = `${this.$t('venue.invoices.provision')} ${transaction.provisionMonth}`;
        break;
      default:
        transactionType = this.$t('venue.invoices.creditNoteNet').toString();
    }

    return transactionType;
  }

  protected transactionNote(transaction: VenueTransaction): string {
    let note: string = transaction.invoiceRef || transaction.note || '';

    if (transaction.invoiceRef && transaction.note) {
      note = `${transaction.invoiceRef} - ${transaction.note}`;
    }

    return note;
  }

  protected isMandatable(transaction: VenueTransaction): boolean {
    const sepaMandateRef: string | null =
      (this.venue.invoiceReceiver.sepa && this.venue.invoiceReceiver.sepa.sepaMandateRef) || null;
    return sepaMandateRef !== null && !!(transaction.invoiceRef && transaction.invoiceRef.length > 0);
  }
}
